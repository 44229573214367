import { useState } from "react";
import { CalendarIcon, RepeatClockIcon } from "@chakra-ui/icons";
import {
  Box,
  ChakraProps,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text
} from "@chakra-ui/react";
import pl from "date-fns/locale/pl";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { CustomSelect } from "components/customSelect/CustomSelect";
import { datePickerCustomStyles } from "utils/datePickerCustomStyles";

import {
  commonProps,
  defaultIntervalInMinutes,
  hourOptions,
  minutesOptions
} from "./constants";
import { getCurrentDate } from "./helpers";

registerLocale("pl", pl as any);

export type TDate = Date | null | undefined;

type TProps = {
  value: TDate;
  onChange: (date: TDate) => void;
  placeholder?: string;
  isMinDate?: boolean;
  minDate?: TDate;
  maxDate?: TDate;
  isDetailedTime?: boolean;
  isTime?: boolean;
  isDate?: boolean;
  isYearVisible?: boolean;
  name: string;
  label?: string;
  errorMessage?: string;
  timeIntervals?: number;
  isDisabled?: boolean;
} & ChakraProps;

export function CustomDatePicker({
  isDetailedTime = false,
  isTime = false,
  isDate = true,
  isYearVisible = true,
  isMinDate,
  name,
  value,
  onChange,
  placeholder = "",
  timeIntervals = defaultIntervalInMinutes,
  minDate,
  maxDate,
  isDisabled = false,
  ...restProps
}: TProps) {
  const currentDate = getCurrentDate();
  const dateFormat =
    `${isDate ? (isYearVisible ? "dd.MM.yyyy" : "dd.MM") : ""} ${
      isTime || isDetailedTime ? "HH:mm" : ""
    }`.trim();

  const CustomTimeInput = ({ value, onChange }: any) => {
    const [hour, minutes] = value.split(":");
    const [, setTime] = useState({ hour, minutes });

    return (
      <Flex width="100%" position="relative" alignItems="center" gap=".25rem">
        <Text>godzina</Text>
        <Box width="100%">
          <CustomSelect
            value={hour}
            onChange={value => {
              setTime(prevTime => ({
                ...prevTime,
                hour: value
              }));
              onChange(`${value}:${minutes}`);
            }}
            options={hourOptions}
            isDisabled={!value}
            {...commonProps}
          />
        </Box>
        :
        <Box width="100%">
          <CustomSelect
            value={minutes}
            onChange={value => {
              setTime(prevTime => ({
                ...prevTime,
                minutes: value
              }));
              onChange(`${hour}:${value}`);
            }}
            options={minutesOptions}
            isDisabled={!value}
            {...commonProps}
          />
        </Box>
      </Flex>
    );
  };

  return (
    <Box>
      <InputGroup sx={datePickerCustomStyles} width="100%">
        <ReactDatePicker
          name={name}
          selected={value}
          onChange={onChange}
          customInput={
            <Input
              name={name}
              backgroundColor="transparent"
              zIndex="2"
              {...restProps}
            />
          }
          minDate={isMinDate ? currentDate : minDate}
          maxDate={maxDate}
          showTimeSelect={isTime}
          showTimeSelectOnly={!isDate}
          timeIntervals={timeIntervals}
          timeFormat="HH:mm"
          dateFormat={dateFormat}
          timeCaption={isTime ? "godzina" : "data"}
          locale="pl"
          placeholderText={placeholder}
          showTimeInput={isDetailedTime}
          customTimeInput={isDetailedTime ? <CustomTimeInput /> : null}
          shouldCloseOnSelect={!isDetailedTime}
          disabled={isDisabled}
        />
        <InputRightElement zIndex="1rem">
          {isDate ? (
            <CalendarIcon
              color={isDisabled ? "gray.500" : "gray.600"}
              zIndex="-1"
            />
          ) : (
            <RepeatClockIcon
              color={isDisabled ? "gray.500" : "gray.600"}
              zIndex="-1"
            />
          )}
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}
