import { Badge, Flex, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";

import FormField, { EFieldType } from "components/formField/FormField";
import { getDisplayValue } from "components/formField/utils";
import { Property } from "components/property";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { TStore } from "types/configFields";
import { timeFormatter } from "utils/dateTime";

import useActiveValue from "../hooks/useActiveValue";

interface IStoreField {
  name: string;
  labelOverride?: string;
  fieldType: EFieldType;
  isDisabled?: boolean;
  isSimpleField?: boolean;
  isLoading?: boolean;
  options?: { value: string; label: string }[];
  extendOnChange?: (value: any, prevValue: any) => void;
  isYearVisible?: boolean;
}

const StoreField = ({
  isSimpleField = false,
  isDisabled = false,
  isLoading,
  name,
  labelOverride,
  fieldType = EFieldType.TEXT,
  options = [],
  extendOnChange,
  isYearVisible
}: IStoreField) => {
  const fieldProps = {
    name,
    labelOverride,
    fieldType,
    options,
    isDisabled,
    isLoading,
    extendOnChange,
    isYearVisible
  };

  const {
    value,
    storeChainValue,
    shouldUseChainValue,
    getActiveValue,
    getStoreBadgeClickability,
    getStoreChainBadgeClickability,
    savedStoreValue,
    setSavedStoreValue,
    setShouldUseChainValue
  } = useActiveValue({
    name
  });
  const { isReadOnly, labels } = useFieldGenerationContext();
  const { setFieldValue } = useFormikContext<TStore>();

  const storeChainDisplayValue = getDisplayValue(storeChainValue, fieldType);
  const formDisplayValue = getDisplayValue(value, fieldType);
  const savedStoreDisplayValue = getDisplayValue(savedStoreValue, fieldType);

  const handleStoreChainBadgeClick = () => {
    if (getStoreChainBadgeClickability() === "pointer") {
      const valueToSave =
        fieldType === EFieldType.TIME ? timeFormatter(value) : value;
      setSavedStoreValue(valueToSave);
      setFieldValue(name, storeChainValue);
      setShouldUseChainValue(true);
    }
  };

  return (
    <Flex direction="column" gap=".5rem">
      <Flex gap=".5rem" alignItems="flex-end">
        {!isSimpleField && (
          <Badge
            fontSize=".6rem"
            variant="solid"
            minWidth="2.5rem"
            colorScheme={getActiveValue() === "store" ? "green" : undefined}
            cursor={getStoreBadgeClickability()}
            height="min-content"
            onClick={() => {
              if (getStoreBadgeClickability() === "pointer") {
                setShouldUseChainValue(false);
                setFieldValue(name, savedStoreValue);
              }
            }}
          >
            Sklep
          </Badge>
        )}
        {isReadOnly || shouldUseChainValue ? (
          <Property
            id={name}
            wordBreak="break-all"
            isDisabled={shouldUseChainValue}
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
          >
            {shouldUseChainValue
              ? savedStoreDisplayValue
              : formDisplayValue || (
                  <Text as="span" color="gray.500" fontStyle="italic">
                    brak danych
                  </Text>
                )}
          </Property>
        ) : (
          <FormField {...fieldProps} />
        )}
      </Flex>

      {!isSimpleField && (
        <Flex fontSize=".75rem" gap=".5rem" alignItems="center">
          <Badge
            fontSize=".6rem"
            variant="solid"
            minWidth="2.5rem"
            backgroundColor={
              getActiveValue() === "chain" ? "green.700" : undefined
            }
            cursor={getStoreChainBadgeClickability()}
            onClick={handleStoreChainBadgeClick}
          >
            Sieć
          </Badge>
          <Text
            color={shouldUseChainValue ? "green.700" : "gray.600"}
            wordBreak="break-all"
            fontStyle={storeChainDisplayValue ? "normal" : "italic"}
          >
            {storeChainDisplayValue || "brak danych"}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default StoreField;
