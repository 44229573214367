import { Field, getIn, useFormikContext } from "formik";

import { FieldCustomCheckbox } from "components/customCheckbox/FieldCustomCheckbox";
import { FieldCustomDatePicker } from "components/customDatePicker/FieldCustomDatePicker";
import { FieldCustomSelect } from "components/customSelect/FieldCustomSelect";
import { FieldWrapper } from "components/fieldWrapper/FieldWrapper";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { CustomInput } from "pages/appConfigKey/configKeysForm/components/CustomInput";
import { TStore } from "types/configFields";

export enum EFieldType {
  TEXT = "TEXT",
  DATE = "DATE",
  TIME = "TIME",
  DATETIME = "DATETIME",
  NUMBER = "NUMBER",
  CURRENCY = "CURRENCY",
  PASSWORD = "PASSWORD",
  EMAIL = "EMAIL",
  TEXTAREA = "TEXTAREA",
  SELECT = "SELECT",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO"
}

type TProps = {
  name: string;
  labelOverride?: string;
  fieldType: EFieldType;
  isDisabled?: boolean;
  isLoading?: boolean;
  options?: { value: string; label: string }[];
  isYearVisible?: boolean;
  extendOnChange?: (value: any, prevValue: any) => void;
};

const FormField = ({
  isDisabled = false,
  isLoading,
  name,
  labelOverride,
  fieldType = EFieldType.TEXT,
  options = [],
  isYearVisible,
  extendOnChange
}: TProps) => {
  const { errors, touched } = useFormikContext<TStore>();
  const { labels } = useFieldGenerationContext();

  const isTouched = getIn(touched, name);

  const getField = () => {
    let field = null;

    switch (fieldType) {
      case EFieldType.TEXT:
        field = (
          <CustomInput
            isSmallLabel
            key={name}
            name={name}
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
            errorMessage={getIn(errors, name)}
            isDisabled={isDisabled}
            isTouched={isTouched}
          />
        );
        break;
      case EFieldType.NUMBER:
        field = (
          <CustomInput
            type="number"
            isSmallLabel
            key={name}
            name={name}
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
            errorMessage={getIn(errors, name)}
            isDisabled={isDisabled}
            isTouched={isTouched}
          />
        );
        break;
      case EFieldType.SELECT:
        field = (
          <FieldWrapper
            name={name}
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
            errorMessage={getIn(errors, name)}
            w="100%"
          >
            <Field
              name={name}
              as={() => (
                <FieldCustomSelect
                  extendOnChange={extendOnChange}
                  placeholder="wybierz opcję"
                  {...{
                    name,
                    isLoading,
                    options,
                    isDisabled
                  }}
                />
              )}
            />
          </FieldWrapper>
        );
        break;
      case EFieldType.CHECKBOX:
        field = (
          <FieldWrapper
            name={name}
            isCheckbox
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
            w="100%"
            errorMessage={getIn(errors, name)}
          >
            <Field name={name} as={() => <FieldCustomCheckbox name={name} />} />
          </FieldWrapper>
        );
        break;
      case EFieldType.TIME:
        field = (
          <FieldWrapper
            name={name}
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
            w="100%"
            errorMessage={getIn(errors, name)}
          >
            <FieldCustomDatePicker
              isTime
              isDate={false}
              key={name}
              name={name}
              isDisabled={isDisabled}
            />
          </FieldWrapper>
        );
        break;
      case EFieldType.DATE:
        field = (
          <FieldWrapper
            name={name}
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
            w="100%"
            errorMessage={getIn(errors, name)}
          >
            <FieldCustomDatePicker
              key={name}
              name={name}
              isDisabled={isDisabled}
            />
          </FieldWrapper>
        );
        break;
      case EFieldType.DATETIME:
        field = (
          <FieldWrapper
            name={name}
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
            w="100%"
            errorMessage={getIn(errors, name)}
          >
            <FieldCustomDatePicker
              key={name}
              name={name}
              isDetailedTime
              isYearVisible={isYearVisible}
              isDisabled={isDisabled}
            />
          </FieldWrapper>
        );
        break;
    }

    return field;
  };

  return getField();
};

export default FormField;
