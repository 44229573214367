import { Badge, Flex, Text } from "@chakra-ui/react";
import { StoreChainConfig } from "api/common";
import { getIn, useFormikContext } from "formik";

import { EFieldType } from "components/formField/FormField";
import FormField from "components/formField/FormField";
import { getDisplayValue } from "components/formField/utils";
import { Property } from "components/property";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";

interface IStoreChainField {
  name: string;
  labelOverride?: string;
  fieldType: EFieldType;
  isDisabled?: boolean;
  isSimpleField?: boolean;
  isLoading?: boolean;
  options?: { value: string; label: string }[];
  extendOnChange?: (value: any, prevValue: any) => void;
}

const StoreChainField = ({
  isSimpleField = false,
  isDisabled = false,
  isLoading,
  name,
  labelOverride,
  fieldType = EFieldType.TEXT,
  options = [],
  extendOnChange
}: IStoreChainField) => {
  const fieldProps = {
    name,
    labelOverride,
    fieldType,
    options,
    isDisabled,
    isLoading,
    extendOnChange
  };

  const { isReadOnly, labels } = useFieldGenerationContext();
  const { values } = useFormikContext<StoreChainConfig>();

  const value = getIn(values, name);

  const formDisplayValue = getDisplayValue(value, fieldType);
  return (
    <Flex direction="column" gap=".5rem">
      <Flex gap=".5rem" alignItems="flex-end">
        {!isSimpleField && (
          <Badge
            fontSize=".6rem"
            variant="solid"
            minWidth="2.5rem"
            colorScheme="green"
            height="min-content"
            cursor="default"
          >
            Sieć
          </Badge>
        )}
        {isReadOnly ? (
          <Property
            id={name}
            wordBreak="break-all"
            label={
              labels[
                labelOverride ? labelOverride : (name as keyof typeof labels)
              ]
            }
          >
            {formDisplayValue || (
              <Text as="span" color="gray.500" fontStyle="italic">
                brak danych
              </Text>
            )}
          </Property>
        ) : (
          <FormField {...fieldProps} />
        )}
      </Flex>
    </Flex>
  );
};

export default StoreChainField;
