import { StoreChainConfig } from "api/common";
import omit from "lodash/omit";

import {
  ECLientPriority,
  EComponentProperty,
  EComponentType,
  EDay,
  EMaintenanceBreakType,
  EProperty,
  ESchedule,
  EStore,
  MaintenanceBreak,
  PeriodRule,
  TAdjustedDay,
  TStore,
  TStoreBaseSchedule,
  TStoreOpeningSchedule
} from "types/configFields";
import {
  dateFormatter,
  posServiceDatetimeFormatter,
  formatDateTimeToISOString,
  formatFromUTCTimeToLocal,
  timeFormatter
} from "utils/dateTime";

import { emptyActivation, emptyBase, emptyClientPriority } from "./consts";

export const getTypeFromDatetime = (dateTimeString: string) => {
  const dailyRegex = /^\d{2}:\d{2}:\d{2}$/;
  const yearlyRegex = /^\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/;
  const oneTimeRegex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;

  if (dailyRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.DAILY;
  } else if (yearlyRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.YEARLY;
  } else if (oneTimeRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.ONE_TIME;
  } else {
    return "UNKNOWN";
  }
};

export const replaceWithInitial = (
  initial: Record<string, any>,
  configPart?: Record<string, any>
) => (!configPart ? initial : configPart);

const openingScheduleMapper = (
  openingSchedule: TStoreOpeningSchedule | null | undefined,
  shouldFormatToLocal?: boolean
) => {
  if (!openingSchedule) {
    return null;
  }

  const { base, exceptions } = openingSchedule;

  let mappedBase: TStoreBaseSchedule | undefined;
  let mappedClosedDays: string[];
  let mappedAdjustedDays: TAdjustedDay[];

  const mergedWithEmptySchedule = Object.keys(emptyBase).reduce((acc, day) => {
    const dayKey = day as EDay;
    acc[dayKey] = {
      ...emptyBase[dayKey],
      ...(base ? base[dayKey] : {})
    };
    return acc;
  }, {} as TStoreBaseSchedule);

  if (!base) {
    mappedBase = undefined;
  } else {
    mappedBase = Object.fromEntries(
      Object.entries(mergedWithEmptySchedule).map(el => {
        const [dayName, value] = el;

        if (!value) {
          return [dayName, { opensAt: "", closesAt: "" }];
        }

        const formattedValue = Object.fromEntries(
          Object.entries(value).map(el => {
            const [intervalKey, time] = el;

            if (!time) {
              return [intervalKey, ""];
            }

            const formattedTime = shouldFormatToLocal
              ? formatFromUTCTimeToLocal(time)
              : timeFormatter(time, true);
            return [intervalKey, formattedTime];
          })
        );

        return [dayName, formattedValue];
      })
    ) as TStoreBaseSchedule;
  }

  if (exceptions && exceptions.closedDays) {
    mappedClosedDays = exceptions.closedDays.map(day => dateFormatter(day));
  } else {
    mappedClosedDays = [];
  }

  if (exceptions && exceptions.adjustedDays) {
    mappedAdjustedDays = exceptions.adjustedDays.map(day => {
      const {
        [EProperty.DAY]: dayName,
        [EProperty.OPENS_AT]: opensAt,
        [EProperty.CLOSES_AT]: closesAt
      } = day;

      return {
        [EProperty.DAY]: dateFormatter(dayName),
        [EProperty.OPENS_AT]: shouldFormatToLocal
          ? formatFromUTCTimeToLocal(opensAt)
          : timeFormatter(opensAt, true),
        [EProperty.CLOSES_AT]: shouldFormatToLocal
          ? formatFromUTCTimeToLocal(closesAt)
          : timeFormatter(closesAt, true)
      };
    });
  } else {
    mappedAdjustedDays = [];
  }

  return {
    [ESchedule.BASE]: mappedBase,
    [ESchedule.EXCEPTIONS]: {
      [ESchedule.CLOSED_DAYS]: mappedClosedDays,
      [ESchedule.ADJUSTED_DAYS]: mappedAdjustedDays
    },
    [ESchedule.IS_ENABLED]: !!openingSchedule.isEnabled
  };
};

const dailyPeriodRulesMapper = (dailyPeriodRules: PeriodRule[]) =>
  dailyPeriodRules.map(rule => ({
    [ECLientPriority.STARTS_AT]: timeFormatter(
      rule[ECLientPriority.STARTS_AT],
      true
    ),
    [ECLientPriority.ENDS_AT]: timeFormatter(
      rule[ECLientPriority.ENDS_AT],
      true
    ),
    [ECLientPriority.PRIORITY]: rule[ECLientPriority.PRIORITY]
  }));

const getPosValues = (type: EComponentType, posValues: any) => {
  switch (type) {
    case EComponentType.ERP:
      return omit(posValues, ["ipos", "posService"]);
    case EComponentType.IPOS:
      return omit(posValues, ["posService"]);
    case EComponentType.POS_SERVICE:
      const values = omit(posValues, ["ipos"]);
      const mappedMaintenanceBreaks = values.posService.maintenanceBreaks.map(
        (item: MaintenanceBreak) => ({
          startsAt: posServiceDatetimeFormatter(item.startsAt, item.type),
          endsAt: posServiceDatetimeFormatter(item.endsAt, item.type)
        })
      );
      return {
        ...values,
        posService: {
          ...values.posService,
          maintenanceBreaks: mappedMaintenanceBreaks
        }
      };
    default:
      return omit(posValues, ["ipos", "posService"]);
  }
};

export const payloadMapper = (
  payload: TStore,
  chainConfig: StoreChainConfig
): TStore => {
  const { parameters, components, openingSchedule, ...restPayload } = payload;

  const mappedSchedule = openingScheduleMapper(openingSchedule);
  const mappedDailyPeriodRules = dailyPeriodRulesMapper(
    parameters.clientPriority.dailyPeriodRules
  );

  return {
    ...restPayload,
    [EStore.PARAMETERS]: {
      ...parameters,
      clientPriority: {
        ...parameters.clientPriority,
        dailyPeriodRules: mappedDailyPeriodRules
      }
    },
    [EStore.COMPONENTS]: {
      ...components,
      pos: components.pos.type
        ? getPosValues(components.pos.type, components.pos)
        : components.pos
    },
    [EStore.OPENING_SCHEDULE]: mappedSchedule
  };
};

export const responseMapper = (response: TStore | null) => {
  if (!response) {
    return {};
  }

  const { openingSchedule, parameters, components, ...restResponse } = response;

  const mappedSchedule = openingScheduleMapper(openingSchedule, true);

  return {
    ...restResponse,
    parameters: {
      ...parameters,
      clientPriority: replaceWithInitial(
        emptyClientPriority,
        parameters?.clientPriority
      ),
      activation: replaceWithInitial(emptyActivation, parameters?.activation)
      // TODO: Uncomment for SSD v.1.17
      // sms: {
      //   ...parameters?.sms,
      //   prefixConfig: replaceWithInitial(
      //     { defaultChannel: "" },
      //     parameters?.sms?.prefixConfig
      //   )
      // }
    },
    components: {
      ...components,
      pos: {
        ...components?.pos,
        posService: {
          ...components.pos.posService,
          maintenanceBreaks: components?.pos?.posService?.[
            EComponentProperty.MAINTENANCE_BREAKS
          ]?.map(item => ({
            type: getTypeFromDatetime(item.startsAt),
            startsAt: formatDateTimeToISOString(item.startsAt),
            endsAt: formatDateTimeToISOString(item.endsAt)
          }))
        }
      }
    },
    openingSchedule: mappedSchedule
  };
};
