import { StoreChainConfigCreate } from "api/common/storeChainConfig";

import {
  EParameterProperty,
  EPurchaseConfirmationDocType
} from "types/configFields";
import {
  EComponent,
  EParameters,
  ESchedule,
  EStore,
  EStoreService
} from "types/configFields";

export const navigationStructure: any = {
  general: {},
  parameters: {
    subSections: {
      [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`]:
        {},
      [`${EStore.PARAMETERS}.${EParameters.SMS}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}`]: {}
    }
  },
  services: {
    subSections: {
      [`${EStore.SERVICES}.${EStoreService.SHORTENER}`]: {},
      [`${EStore.SERVICES}.${EStoreService.SYNERISE}`]: {},
      [`${EStore.SERVICES}.${EStoreService.ERP}`]: {}
    }
  },
  components: {
    subSections: {
      [`${EStore.COMPONENTS}.${EComponent.AIFI}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.ADYEN}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.POS}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`]: {}
    }
  },
  openingSchedule: {
    subSections: {
      [`${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}`]: {},
      [`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}`]: {}
    }
  }
};

export const menuItemStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: ".8rem",
  p: ".125rem 1rem",
  _hover: {
    cursor: "pointer",
    backgroundColor: "gray.200"
  }
} as const;

export const sectionHeaderStyles = {
  borderRadius: ".5rem",
  fontWeight: "bold",
  mb: "1rem",
  fontSize: "14px"
};

export const MOCK_CARD_ISSUER_ACTIONS = [
  {
    cardIssuerBin: "My-new-bin-number-123",
    action: {
      type: "OVERRIDE_PREAUTH",
      preauthAmount: 123
    }
  },
  {
    cardIssuerBin: "GIGABIN-123",
    action: {
      type: "OVERRIDE_PREAUTH",
      preauthAmount: 999
    }
  },
  {
    cardIssuerBrand: "GIGA-BRAND-123",
    action: {
      type: "OVERRIDE_PREAUTH",
      preauthAmount: 999
    }
  }
];

export const MOCK_PRODUCT_IMPORT = {
  importImagesFromPIM: true,
  aifiImportEnabled: true
};

export const MOCK_SMS_LANGUAGES = [
  { prefix: "+48", language: "PL" },
  { prefix: "+49", language: "DE" }
];

export const MOCK_MONITOR_STATE = {
  isEnabled: true,
  disabledOutsideWorkingHours: true,
  actions: {
    storeStateModifier: {
      isActive: true,
      closeThreshold: 15,
      closePeriod: 30,
      openThreshold: 12,
      openPeriod: 14
    }
  }
};

export const initialData: StoreChainConfigCreate = {
  chainId: "",
  name: "",
  parameters: {
    activation: {
      requireForExistingClients: false,
      requireForNewClients: false
    },
    appPreauthEnabled: false,
    cardIssuerActions: undefined,
    countryCode: "PL",
    currency: "PLN",
    defaultLanguage: "PL",
    defaultPreauthAmount: 0,
    phoneBlacklistId: "",
    productImport: {
      importImagesFromPIM: false,
      aifiImportEnabled: false
    },
    purchaseConfirmationDoc: {
      type: "INSTA",
      domain: ""
    },
    smsLanguages: [
      {
        prefix: "+48",
        language: "PL"
      }
    ],
    sms: {
      prefixConfig: {
        defaultChannel: "",
        prefixes: []
      },
      activationSms: "SMS",
      autoRescueInitSms: "SMS",
      exitSms: "SMS",
      firstRescueAttemptSms: "SMS",
      lastRescueAttemptSms: "SMS",
      paymentFailedSms: "SMS",
      paymentReminder1Sms: "SMS",
      paymentReminder2Sms: "SMS",
      paymentReminder3Sms: "SMS",
      paymentReminder4Sms: "SMS",
      paymentReminder5Sms: "SMS",
      pblRequestedSms: "SMS",
      receiptSms: "SMS",
      welcomeSms: "SMS",
      autoRescueInitSmsThreshold: "",
      firstRescueAttemptSmsThreshold: "",
      lastRescueAttemptSmsThreshold: "",
      paymentReminder1SmsThreshold: "",
      paymentReminder2SmsThreshold: "",
      paymentReminder3SmsThreshold: "",
      paymentReminder4SmsThreshold: "",
      paymentReminder5SmsThreshold: ""
    },
    timeZone: "+01:00",
    clientPriority: {
      ordersRules: [],
      dailyPeriodRules: [],
      periodRules: [],
      vipRule: {
        enabled: false
      }
    },
    retryPaymentMethods: []
  },
  services: {
    shortener: {
      serviceKey: "",
      domain: ""
    },
    synerise: {
      serviceKey: "",
      superLoginServiceKey: ""
    },
    erp: {
      isEnabled: false,
      serviceKey: ""
    }
  },
  components: {
    aifi: {
      monitors: {
        state: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        availability: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        paperState: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        }
      },
      serviceKey: ""
    },
    adyen: {
      monitors: {
        state: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        availability: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        paperState: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        }
      },
      serviceKey: "",
      merchantAccount: "",
      defaultLocale: ""
    },
    pos: {
      serviceKey: "",
      type: "",
      ipos: {},
      posService: {},
      monitors: {
        state: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        availability: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        paperState: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        }
      }
    },
    infokiosk: {
      monitors: {
        state: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        availability: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        paperState: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        }
      },
      serviceKey: "",
      heartbeatThreshold: 0
    },
    powerBouncer: {
      heartbeatThreshold: 0,
      aifiZoneId: "",
      ageEstimation: {
        minAdultAge: 0
      },
      helpLink: "",
      ioController: {
        serviceKey: ""
      },
      monitors: {
        qrReaderAvailability: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        },
        availability: {
          isEnabled: false,
          actions: {
            storeStateModifier: {
              isActive: false,
              closeThreshold: 0,
              closePeriod: 0,
              openThreshold: 0,
              openPeriod: 0
            }
          },
          disabledOutsideWorkingHours: false
        }
      }
    }
  },
  openingSchedule: null
};

export const purchaseConfirmationDocTypesOptions = Object.values(
  EPurchaseConfirmationDocType
).map(value => ({
  value,
  label: value
}));

export const emptySmsLanguage = {
  prefix: "",
  language: ""
};

export const emptyPaymentMethod = {
  name: "",
  type: ""
};

export const emptyAdjustedDay = {
  day: "",
  opensAt: "",
  closesAt: ""
};
