export const noDataStyles = {
  padding: "1rem",
  borderRadius: "1rem",
  fontSize: ".8rem"
} as const;

export const tilesContainerStyles = {
  gap: ".5rem",
  padding: ".5rem",
  backgroundColor: "gray.200",
  w: "full",
  borderRadius: ".5rem",
  border: "1px solid",
  borderColor: "transparent",
  flexWrap: "wrap"
} as const;

export const tileStyles = {
  direction: "column",
  gap: "2rem",
  maxW: "20rem",
  minW: "15rem",
  padding: "1rem",
  border: "1px solid",
  borderColor: "gray.400",
  backgroundColor: "white",
  borderRadius: "1rem"
} as const;

export const sectionStyles = {
  direction: "column",
  gap: "2rem",
  marginBottom: "2rem"
} as const;

export const headerStyles = {
  fontSize: "1rem",
  fontWeight: "bold",
  m: "0",
  mb: "1rem"
} as const;

export const stickyHeadingStyles = {
  width: "100%",
  fontSize: "1rem",
  fontWeight: "bold",
  padding: "1rem",
  position: "sticky",
  top: "0",
  backgroundColor: "white",
  zIndex: 1,
  boxShadow: "15px 10px 30px 0px #00000011"
} as const;

export const cardStyles = {
  minWidth: "95%",
  direction: "column",
  gap: "1.5rem",
  maxW: "40rem"
} as const;
