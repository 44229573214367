import { useEffect, useState } from "react";
import { ComponentPropertyStatus } from "api/common/storeComponentProperty";
import {
  GetComponentPropertiesResponse,
  getComponentPropertiesUrl
} from "api/getComponentProperties";
import {
  ListStoreConfigsResponse,
  listStoreConfigsUrl,
  ListStoreConfigsQueryParams
} from "api/listStoreConfigs";

import {
  TResult,
  useFetchWithQueryParams
} from "hooks/useFetchWithQueryParams";

import { StoreData } from "./types";

export function useEnergyDrinksStoresData() {
  const {
    fetchData: fetchStoresData,
    error,
    isLoading: isLoadingFetchStores
  } = useFetchWithQueryParams<
    ListStoreConfigsQueryParams,
    ListStoreConfigsResponse
  >({
    url: listStoreConfigsUrl
  });

  const { fetchData: fetchPowerBouncerState } = useFetchWithQueryParams<
    {},
    GetComponentPropertiesResponse
  >({});

  const [stores, setStores] = useState<StoreData[]>([]);

  useEffect(
    () => {
      async function fetchPowerBouncerStatus(storeId: string) {
        const response = await fetchPowerBouncerState({
          url: getComponentPropertiesUrl(storeId)
        });

        if (!response.data) {
          return;
        }

        setStores(stores =>
          stores.map(store =>
            store.storeId === storeId
              ? {
                  ...store,
                  powerBouncerStatus: response.data?.properties.status,
                  isLoadingPowerBouncerStatus: false
                }
              : store
          )
        );
      }

      async function fetchStores() {
        let continuationToken = undefined;

        do {
          const response: TResult<ListStoreConfigsResponse> =
            await fetchStoresData({
              queryParams: {
                nextPageMarker: continuationToken
              }
            });

          if (!response.data) {
            return;
          }

          const { nextPageMarker, stores: energyDrinksStores } = response.data;

          energyDrinksStores
            .map(store => ({
              ...store,
              isLoadingPowerBouncerStatus: true
            }))
            .forEach(store => fetchPowerBouncerStatus(store.storeId));

          if (!continuationToken) {
            setStores(energyDrinksStores);
          } else {
            setStores(stores => [...stores, ...energyDrinksStores]);
          }

          continuationToken = nextPageMarker;
        } while (continuationToken);
      }
      fetchStores();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function onPowerBouncerStatusChange(
    storeId: string,
    status: ComponentPropertyStatus
  ) {
    setStores(stores =>
      stores.map(store =>
        store.storeId !== storeId
          ? store
          : { ...store, powerBouncerStatus: status }
      )
    );
  }

  return {
    isLoadingFetchStores,
    stores,
    error,
    onPowerBouncerStatusChange
  };
}
