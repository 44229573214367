import { timeFormatter } from "utils/dateTime";

import { EFieldType } from "./FormField";

const formatString = (value: string) => {
  const date = new Date(value);
  if (!isNaN(date.getTime())) {
    return date.toLocaleString();
  }
  return value;
};

export const getDisplayValue = (value: unknown, fieldType: EFieldType) => {
  if (value === undefined || value === null) {
    return undefined;
  }

  if (typeof value === "boolean" || typeof value === "number") {
    return String(value);
  }

  if (value instanceof Date && !isNaN(value.valueOf())) {
    return value.toLocaleString();
  }

  if (typeof value === "string") {
    if (fieldType === EFieldType.DATE || fieldType === EFieldType.DATETIME) {
      return formatString(value);
    }

    if (fieldType === EFieldType.TIME) {
      return timeFormatter(value);
    }
  }

  return String(value);
};
