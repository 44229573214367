import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text
} from "@chakra-ui/react";
import { StoreChainConfigCreate } from "api/common/storeChainConfig";
import { FieldArray, getIn, useFormikContext } from "formik";
import isString from "lodash/isString";
import { CirclePlusIcon, Trash2 } from "lucide-react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import {
  emptyBinAction,
  emptyBrandAction,
  getSmsFields,
  smsOptions
} from "pages/store/consts";
import {
  noDataStyles,
  stickyHeadingStyles,
  tilesContainerStyles,
  tileStyles
} from "pages/store/styles";
import {
  EParameters,
  EParameterProperty,
  EStore,
  EParameterSmsLanguage,
  ECLientPriority,
  TCardIssuerActionBin,
  TCardIssuerActionScheme,
  PaymentMethodFields
} from "types/configFields";

import Action from "../components/Action";
import DailyPeriodRules from "../components/DailyPeriodRules";
import OrdersRules from "../components/OrdersRules";
import PeriodRules from "../components/PeriodRules";
import StoreChainField from "../components/StoreChainField";
import {
  emptyPaymentMethod,
  emptySmsLanguage,
  purchaseConfirmationDocTypesOptions,
  sectionHeaderStyles
} from "../consts";
import { headers } from "../labels";

const Parameters = () => {
  const { values, setFieldValue, errors } =
    useFormikContext<StoreChainConfigCreate>();
  const { isReadOnly } = useFieldGenerationContext();
  const smsLanguages = values?.parameters?.smsLanguages;
  const retryPaymentMethods = values?.parameters?.retryPaymentMethods;
  const cardIssuerActions = values?.parameters?.cardIssuerActions || [];
  const smsLanguagesError = errors.parameters?.smsLanguages;
  // TODO: Uncomment for SSD v.1.17
  // const prefixes = values?.parameters?.sms?.prefixConfig?.prefixes || [];

  const hasSmsLanugagesError = Boolean(
    smsLanguagesError && isString(smsLanguagesError)
  );

  const ordersRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.ORDERS_RULES
    ] || [];
  const dailyPeriodRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.DAILY_PERIOD_RULES
    ] || [];
  const periodRules =
    values?.[EStore.PARAMETERS]?.[EParameters.CLIENT_PRIORITY]?.[
      ECLientPriority.PERIOD_RULES
    ] || [];

  const handleAddAction = (
    emptyAction: TCardIssuerActionBin | TCardIssuerActionScheme
  ) => {
    const currentValues = getIn(
      values,
      `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`
    );
    setFieldValue(`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`, [
      ...(currentValues || []),
      emptyAction
    ]);
  };

  const handleRemoveAction = (index: number) => {
    const currentValues: typeof cardIssuerActions = getIn(
      values,
      `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`
    );
    const filteredValues = currentValues.filter((_, i) => i !== index);
    setFieldValue(
      `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`,
      filteredValues
    );
  };

  return (
    <AccordionItem id="parameters">
      <AccordionButton
        mt="0.5rem"
        pb="1rem"
        {...stickyHeadingStyles}
        borderRadius=".5rem"
      >
        Parametry konfiguracyjne sieci sklepów
        <AccordionIcon position="relative" />
      </AccordionButton>
      <AccordionPanel pt="0">
        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`}
            {...sectionHeaderStyles}
          >
            Konfiguracja dotycząca dokumentu potwierdzającego dokonanie zakupów,
            wysyłanego do klienta
          </Text>
          <StoreChainField
            fieldType={EFieldType.SELECT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.TYPE}`}
            options={purchaseConfirmationDocTypesOptions}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.DOMAIN}`}
          />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.DEFAULT_LANGUAGE}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.COUNTRY_CODE}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.DEFAULT_PREAUTH_AMOUNT}`}
          />
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.APP_PREAUTH_ENABLED}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.CURRENCY}`}
          />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`}
            {...sectionHeaderStyles}
          >
            Dane dotyczące aktywacji konta (w procesie na kartę)
          </Text>
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS}`}
          />
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_NEW_CLIENTS}`}
          />
        </BorderedBox>

        <BorderedBox>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}`}
            {...sectionHeaderStyles}
          >
            Lista języków komunikacji wraz z przyporządkowanymi prefixami
            numerów telefonów
          </Text>
          <FormControl isInvalid={hasSmsLanugagesError}>
            <FieldArray
              name={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}`}
            >
              {({ push, remove }) => (
                <>
                  {!isReadOnly && (
                    <Button
                      onClick={() => push(emptySmsLanguage)}
                      variant="ghost"
                      leftIcon={<CirclePlusIcon size="1rem" />}
                      mb="1rem"
                      justifyContent="flex-start"
                    >
                      Dodaj nowy język
                    </Button>
                  )}

                  <Flex
                    {...tilesContainerStyles}
                    mb="1rem"
                    border={hasSmsLanugagesError ? "1px solid red" : ""}
                  >
                    {smsLanguages && smsLanguages.length > 0 ? (
                      smsLanguages.map((_, index) => (
                        <Flex {...tileStyles} mt="0" key={index}>
                          <StoreChainField
                            isSimpleField
                            fieldType={EFieldType.TEXT}
                            name={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}[${index}].${EParameterSmsLanguage.PREFIX}`}
                            labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}[0].${EParameterSmsLanguage.PREFIX}`}
                          />
                          <StoreChainField
                            isSimpleField
                            fieldType={EFieldType.TEXT}
                            name={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}[${index}].${EParameterSmsLanguage.LANGUAGE}`}
                            labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}[0].${EParameterSmsLanguage.LANGUAGE}`}
                          />
                          {!isReadOnly && (
                            <Button
                              onClick={() => remove(index)}
                              variant="ghost"
                              colorScheme="red"
                              color="red.dark"
                              width="min-content"
                              marginTop="auto"
                              marginLeft="auto"
                              leftIcon={<Trash2 size="1rem" />}
                            >
                              Usuń
                            </Button>
                          )}
                        </Flex>
                      ))
                    ) : (
                      <Text {...noDataStyles}>Brak języków komunikacji</Text>
                    )}
                  </Flex>
                  <FormErrorMessage>
                    {smsLanguagesError &&
                      isString(smsLanguagesError) &&
                      smsLanguagesError}
                  </FormErrorMessage>
                </>
              )}
            </FieldArray>
          </FormControl>
        </BorderedBox>

        {/* TODO: Uncomment for SSD v.1.17 */}
        {/* <BorderedBox>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`}
            fontSize="1rem"
            fontWeight="bold"
            mb="1rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`
              ]
            }
          </Text>
          <StoreChainField
            fieldType={EFieldType.SELECT}
            name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.DEFAULT_CHANNEL}`}
            options={channelOptions}
          />

          <Text
            id={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`}
            fontSize="0.8rem"
            fontWeight="bold"
            mb="1rem"
            mt="2rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`
              ]
            }
          </Text>

          <FieldArray
            name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`}
          >
            {({ push, remove }) => (
              <>
                {!isReadOnly && (
                  <Button
                    onClick={() => push(emptyPrefixConfig)}
                    variant="ghost"
                    leftIcon={<CirclePlusIcon size="1rem" />}
                    mb="1rem"
                  >
                    Dodaj nową konfigurację prefixów
                  </Button>
                )}

                <Flex {...tilesContainerStyles} mb="2em">
                  {prefixes.length > 0 ? (
                    prefixes.map((_, index) => (
                      <Flex {...tileStyles} mt="0" key={index}>
                        <StoreChainField
                          fieldType={EFieldType.TEXT}
                          name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.${index}.${EPrefixFields.PREFIX}`}
                          labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.PREFIX}`}
                        />
                        <StoreChainField
                          fieldType={EFieldType.SELECT}
                          name={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.${index}.${EPrefixFields.CHANNEL}`}
                          labelOverride={`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.CHANNEL}`}
                          options={channelOptions}
                        />

                        {!isReadOnly && (
                          <Button
                            onClick={() => remove(index)}
                            variant="ghost"
                            colorScheme="red"
                            color="red.dark"
                            width="min-content"
                            marginTop="auto"
                            marginLeft="auto"
                            leftIcon={<Trash2 size="1rem" />}
                          >
                            Usuń
                          </Button>
                        )}
                      </Flex>
                    ))
                  ) : (
                    <Text {...noDataStyles}>Brak konfiguracji prefixów</Text>
                  )}
                </Flex>
              </>
            )}
          </FieldArray>
        </BorderedBox> */}

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.SMS}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="1rem"
          >
            Konfiguracja wysyłki wiadomości SMS w danej sieci sklepów
          </Text>
          <Flex gap="1rem" columnGap="3rem" flexWrap="wrap">
            {getSmsFields().map(({ smsKey, thresholdKey }) => (
              <BorderedBox
                minWidth="20rem"
                maxWidth="20rem"
                key={smsKey}
                display="flex"
                flexDir="column"
                gap="2rem"
                border={thresholdKey ? undefined : "none"}
              >
                <StoreChainField
                  fieldType={EFieldType.SELECT}
                  name={`${EStore.PARAMETERS}.${EParameters.SMS}.${smsKey}`}
                  options={smsOptions[smsKey]}
                />
                {thresholdKey && (
                  <StoreChainField
                    fieldType={EFieldType.TEXT}
                    name={`${EStore.PARAMETERS}.${EParameters.SMS}.${thresholdKey}`}
                  />
                )}
              </BorderedBox>
            ))}
          </Flex>
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="1rem"
          >
            Konfiguracja importu produktów
          </Text>
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.IMPORT_IMAGES_FROM_PIM}`}
          />
          <StoreChainField
            fieldType={EFieldType.CHECKBOX}
            name={`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.AIFI_IMPORT_ENABLED}`}
          />
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <Flex flexDir="column" justifyContent="space-between">
            <Text
              id={`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`}
              fontSize="1rem"
              fontWeight="bold"
              m="0"
              mb="1rem"
            >
              {
                headers[
                  `${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`
                ]
              }
            </Text>
            {!isReadOnly && (
              <Flex gap="1rem">
                <Button
                  onClick={() => handleAddAction(emptyBinAction)}
                  variant="ghost"
                  leftIcon={<CirclePlusIcon size="1rem" />}
                >
                  Card Issuer BIN Action
                </Button>
                <Button
                  onClick={() => handleAddAction(emptyBrandAction)}
                  variant="ghost"
                  leftIcon={<CirclePlusIcon size="1rem" />}
                >
                  Card Issuer Brand Action
                </Button>
              </Flex>
            )}
          </Flex>
          <Flex {...tilesContainerStyles}>
            {cardIssuerActions.length > 0 ? (
              cardIssuerActions.map((_action, index) => (
                <Action
                  key={index}
                  index={index}
                  onRemove={() => handleRemoveAction(index)}
                />
              ))
            ) : (
              <Text {...noDataStyles}>Brak akcji</Text>
            )}
          </Flex>
        </BorderedBox>

        <BorderedBox gap="1.5rem" display="flex" flexDirection="column">
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.PHONE_BLACKLIST_ID}`}
          />
          <StoreChainField
            fieldType={EFieldType.TEXT}
            name={`${EStore.PARAMETERS}.${EParameters.TIME_ZONE}`}
          />
        </BorderedBox>

        <BorderedBox>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`}
            fontSize="1rem"
            fontWeight="bold"
            m="0"
            mb="2rem"
          >
            {headers[`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`]}
          </Text>

          <OrdersRules ordersRules={ordersRules} />
          <DailyPeriodRules dailyPeriodRules={dailyPeriodRules} />
          <PeriodRules periodRules={periodRules} />

          <Text
            id={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`}
            fontSize="0.8rem"
            fontWeight="bold"
            m="0"
            mt="2rem"
          >
            {
              headers[
                `${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`
              ]
            }
          </Text>
          <BorderedBox display="flex" flexDirection="column" gap="1rem">
            <StoreChainField
              isDisabled
              fieldType={EFieldType.CHECKBOX}
              name={`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}.${ECLientPriority.ENABLED}`}
            />
          </BorderedBox>
        </BorderedBox>

        <BorderedBox>
          <Text
            id={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}`}
            {...sectionHeaderStyles}
          >
            Lista metod płatności dla ręcznych ponowień płatności (w SuperApp)
          </Text>
          <FieldArray
            name={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}`}
          >
            {({ push, remove }) => (
              <>
                {!isReadOnly && (
                  <Button
                    onClick={() => push(emptyPaymentMethod)}
                    variant="ghost"
                    leftIcon={<CirclePlusIcon size="1rem" />}
                    mb="1rem"
                    justifyContent="flex-start"
                  >
                    Dodaj nową metodę płatności
                  </Button>
                )}

                <Flex {...tilesContainerStyles} mb="1rem">
                  {retryPaymentMethods && retryPaymentMethods.length > 0 ? (
                    retryPaymentMethods.map((_, index) => (
                      <Flex {...tileStyles} mt="0" key={index}>
                        <StoreChainField
                          fieldType={EFieldType.TEXT}
                          name={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}[${index}].${PaymentMethodFields.NAME}`}
                          labelOverride={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}[0].${PaymentMethodFields.NAME}`}
                        />
                        <StoreChainField
                          fieldType={EFieldType.TEXT}
                          name={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}[${index}].${PaymentMethodFields.TYPE}`}
                          labelOverride={`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}[0].${PaymentMethodFields.TYPE}`}
                        />
                        {!isReadOnly && (
                          <Button
                            onClick={() => remove(index)}
                            variant="ghost"
                            colorScheme="red"
                            color="red.dark"
                            width="min-content"
                            marginTop="auto"
                            marginLeft="auto"
                            leftIcon={<Trash2 size="1rem" />}
                          >
                            Usuń
                          </Button>
                        )}
                      </Flex>
                    ))
                  ) : (
                    <Text {...noDataStyles}>Brak języków komunikacji</Text>
                  )}
                </Flex>
              </>
            )}
          </FieldArray>
        </BorderedBox>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Parameters;
