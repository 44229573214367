import { EMaintenanceBreakType } from "types/configFields";

export const testIsTime = (value: string) =>
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
export const testIsDate = (value: string) =>
  /^([0-2]?[0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/\d{4}$/.test(value);

export const formatFromUTCTimeToLocal = (utcTimeString: string) => {
  const date = new Date();

  const [utcHours, utcMinutes] = utcTimeString.split(":").map(Number);

  date.setUTCHours(utcHours, utcMinutes, 0, 0);

  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit"
  });
};

export const timeFormatter = (isoString: string, shouldUseUTC?: boolean) => {
  if (!isoString) {
    return "";
  }

  let date;

  if (testIsTime(isoString)) {
    // Get UTC datetime from time string
    date = new Date();
    const [isoHours, isoMinutes] = isoString.split(":").map(Number);
    date.setHours(isoHours, isoMinutes, 0, 0);
  } else {
    date = new Date(isoString);
  }

  let hours;
  let minutes;

  if (shouldUseUTC) {
    hours = date.getUTCHours().toString().padStart(2, "0");
    minutes = date.getUTCMinutes().toString().padStart(2, "0");
  } else {
    hours = date.getHours().toString().padStart(2, "0");
    minutes = date.getMinutes().toString().padStart(2, "0");
  }

  return `${hours}:${minutes}`;
};

export const dateFormatter = (isoString: string) => {
  if (testIsDate(isoString)) return isoString;

  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const posServiceDatetimeFormatter = (
  isoString: string,
  type?: EMaintenanceBreakType | "",
  shouldUseUTC = true
) => {
  if (!isoString) return "";
  if (testIsDate(isoString)) return isoString;

  const date = new Date(isoString);

  const day = String(
    shouldUseUTC ? date.getUTCDate() : date.getDate()
  ).padStart(2, "0");
  const month = String(
    shouldUseUTC ? date.getUTCMonth() + 1 : date.getMonth() + 1
  ).padStart(2, "0"); // Months are zero-indexed
  const year = shouldUseUTC ? date.getUTCFullYear() : date.getFullYear();

  const hours = String(
    shouldUseUTC ? date.getUTCHours() : date.getHours()
  ).padStart(2, "0");
  const minutes = String(
    shouldUseUTC ? date.getUTCMinutes() : date.getMinutes()
  ).padStart(2, "0");
  const seconds = String(
    shouldUseUTC ? date.getUTCSeconds() : date.getSeconds()
  ).padStart(2, "0");

  switch (type) {
    case EMaintenanceBreakType.DAILY:
      return `${hours}:${minutes}:${seconds}`;
    case EMaintenanceBreakType.YEARLY:
      return `${day}/${month} ${hours}:${minutes}:${seconds}`;
    case EMaintenanceBreakType.ONE_TIME:
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    default:
      return isoString;
  }
};

export const formatDateTimeToISOString = (dateString: string) => {
  const [datePart, timePart] = dateString.includes(" ")
    ? dateString.split(" ")
    : [null, dateString];

  const today = new Date();
  const day = datePart
    ? datePart.split("/")[0]
    : String(today.getDate()).padStart(2, "0");
  const month = datePart
    ? datePart.split("/")[1]
    : String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = datePart ? datePart.split("/")[2] : String(today.getFullYear());

  const [hours, minutes, seconds] = timePart.split(":");

  const date = new Date(
    `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
  );

  return date.toISOString();
};
