export enum EStoreType {
  REGULAR = "REGULAR",
  HYBRID = "HYBRID"
}

export enum EStoreState {
  PRE_LIVE = "PRE_LIVE",
  LIVE = "LIVE",
  ARCHIVED = "ARCHIVED"
}

export enum EPurchaseConfirmationDocType {
  ERECEIPT = "ERECEIPT",
  INSTA = "INSTA",
  SUMMARY = "SUMMARY"
}

export enum EPowerBouncerStatus {
  ACTIVE = "ACTIVE",
  ALWAYS_OPEN = "ALWAYS_OPEN",
  MAINTENANCE = "MAINTENANCE"
}

export enum EMessage {
  SMS = "SMS",
  WHATSAPP = "WHATSAPP",
  DISABLED = "DISABLED"
  // TODO: Uncomment for SSD v.1.17
  // PREFIX_CONFIG = "PREFIX_CONFIG"
}

export enum EMessageNonDisable {
  SMS = "SMS",
  WHATSAPP = "WHATSAPP"
  // TODO: Uncomment for SSD v.1.17
  // PREFIX_CONFIG = "PREFIX_CONFIG"
}

export enum EIssuerAction {
  BIN = "BIN",
  SCHEME = "SCHEME"
}
export enum EIssuerActionType {
  OVERRIDE_PREAUTH = "OVERRIDE_PREAUTH",
  DENY_ENTRY = "DENY_ENTRY"
}

export enum EIssuerActionProperty {
  CARD_ISSUER_BIN = "cardIssuerBin",
  CARD_ISSUER_BRAND = "cardIssuerBrand",
  ACTION = "action",
  TYPE = "type",
  PREAUTH_AMOUNT = "preauthAmount"
}

export type TActionOverridePreAuth = {
  [EIssuerActionProperty.TYPE]: EIssuerActionType.OVERRIDE_PREAUTH;
  [EIssuerActionProperty.PREAUTH_AMOUNT]: string;
};
export type TActionDenyEntry = {
  [EIssuerActionProperty.TYPE]: EIssuerActionType.DENY_ENTRY;
};
export type TCardIssuerActionBin = {
  [EIssuerActionProperty.CARD_ISSUER_BIN]: string;
  [EIssuerActionProperty.ACTION]: TActionOverridePreAuth | TActionDenyEntry;
};
export type TCardIssuerActionScheme = {
  [EIssuerActionProperty.CARD_ISSUER_BRAND]: string;
  [EIssuerActionProperty.ACTION]: TActionOverridePreAuth | TActionDenyEntry;
};

export enum EMonitor {
  IS_ENABLED = "isEnabled",
  IS_DISABLED_OUTSIDE_WORKING_HOURS = "disabledOutsideWorkingHours",
  ACTIONS = "actions",
  STORE_STATE_MODIFIER = "storeStateModifier",
  IS_ACTIVE = "isActive",
  CLOSE_THRESHOLD = "closeThreshold",
  CLOSE_PERIOD = "closePeriod",
  OPEN_THRESHOLD = "openThreshold",
  OPEN_PERIOD = "openPeriod"
}

export type TStoreAction = {
  [EMonitor.STORE_STATE_MODIFIER]?: {
    [EMonitor.IS_ACTIVE]?: boolean;
    [EMonitor.CLOSE_THRESHOLD]?: string; // 0 - 100
    [EMonitor.CLOSE_PERIOD]?: string; // s
    [EMonitor.OPEN_THRESHOLD]?: string; // 0 - 100
    [EMonitor.OPEN_PERIOD]?: string; // s
  };
};

export type TMonitor = {
  [EMonitor.IS_ENABLED]?: boolean;
  [EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS]?: boolean;
  [EMonitor.ACTIONS]?: TStoreAction;
};

export enum PaymentMethodFields {
  NAME = "name",
  TYPE = "type"
}

export type PaymentMethod = {
  [PaymentMethodFields.NAME]: string;
  [PaymentMethodFields.TYPE]: string;
};

export enum EParameters {
  PURCHASE_CONFIRMATION_DOC = "purchaseConfirmationDoc",
  DEFAULT_LANGUAGE = "defaultLanguage",
  COUNTRY_CODE = "countryCode",
  DEFAULT_PREAUTH_AMOUNT = "defaultPreauthAmount",
  APP_PREAUTH_ENABLED = "appPreauthEnabled",
  CURRENCY = "currency",
  ACTIVATION = "activation",
  SMS = "sms",
  SMS_LANGUAGES = "smsLanguages",
  PRODUCT_IMPORT = "productImport",
  CARD_ISSUER_ACTIONS = "cardIssuerActions",
  PHONE_BLACKLIST_ID = "phoneBlacklistId",
  TIME_ZONE = "timeZone",
  CLIENT_PRIORITY = "clientPriority",
  RETRY_PAYMENT_METHODS = "retryPaymentMethods"
}

export enum ECLientPriority {
  ORDERS_RULES = "ordersRules",
  ORDERS_THRESHOLD = "ordersThreshold",
  PRIORITY = "priority",
  DAILY_PERIOD_RULES = "dailyPeriodRules",
  STARTS_AT = "startsAt",
  ENDS_AT = "endsAt",
  PERIOD_RULES = "periodRules",
  STORE_RULE = "storeRule",
  VIP_RULE = "vipRule",
  ENABLED = "enabled"
}

export enum EParameterProperty {
  TYPE = "type",
  DOMAIN = "domain",
  REQUIRE_FOR_EXISTING_CLIENTS = "requireForExistingClients",
  REQUIRE_FOR_NEW_CLIENTS = "requireForNewClients",
  IMPORT_IMAGES_FROM_PIM = "importImagesFromPIM",
  AIFI_IMPORT_ENABLED = "aifiImportEnabled",
  CARD_ISSUER_ACTIONS = "cardIssuerActions",
  PREFIX_CONFIG = "prefixConfig"
}

export enum EPrefixConfigFields {
  DEFAULT_CHANNEL = "defaultChannel",
  PREFIXES = "prefixes"
}

export enum EPrefixFields {
  PREFIX = "prefix",
  CHANNEL = "channel"
}

export enum ECardIssuerActions {
  CARD_ISSUER_BIN = "cardIssuerBin",
  CARD_ISSUER_BRAND = "cardIssuerBrand",
  ACTION = "action"
}

export enum ECardIssuerAction {
  TYPE = "type",
  PREAUTH_AMOUNT = "preauthAmount"
}

export enum EParameterSmsLanguage {
  PREFIX = "prefix",
  LANGUAGE = "language"
}

export enum EMaintenanceBreakType {
  DAILY = "CODZIENNA",
  YEARLY = "COROCZNA",
  ONE_TIME = "JEDNORAZOWA"
}

export enum EMaintenanceBreak {
  TYPE = "type",
  STARTS_AT = "startsAt",
  ENDS_AT = "endsAt"
}

export enum EParameterPropertySms {
  WELCOME_SMS = "welcomeSms",
  RECEIPT_SMS = "receiptSms",
  EXIT_SMS = "exitSms",
  PAYMENT_FAILED_SMS = "paymentFailedSms",
  PBL_REQUESTED_SMS = "pblRequestedSms",
  ACTIVATION_SMS = "activationSms",
  AUTO_RESCUE_INIT_SMS = "autoRescueInitSms",
  FIRST_RESCUE_ATTEMPT_SMS = "firstRescueAttemptSms",
  LAST_RESCUE_ATTEMPT_SMS = "lastRescueAttemptSms",
  PAYMENT_REMINDER1_SMS = "paymentReminder1Sms",
  PAYMENT_REMINDER2_SMS = "paymentReminder2Sms",
  PAYMENT_REMINDER3_SMS = "paymentReminder3Sms",
  PAYMENT_REMINDER4_SMS = "paymentReminder4Sms",
  PAYMENT_REMINDER5_SMS = "paymentReminder5Sms"
}

export enum EParameterPropertySmsThreshold {
  AUTO_RESCUE_INIT_SMS_THRESHOLD = "autoRescueInitSmsThreshold",
  FIRST_RESCUE_ATTEMPT_SMS_THRESHOLD = "firstRescueAttemptSmsThreshold",
  LAST_RESCUE_ATTEMPT_SMS_THRESHOLD = "lastRescueAttemptSmsThreshold",
  PAYMENT_REMINDER1_SMS_THRESHOLD = "paymentReminder1SmsThreshold",
  PAYMENT_REMINDER2_SMS_THRESHOLD = "paymentReminder2SmsThreshold",
  PAYMENT_REMINDER3_SMS_THRESHOLD = "paymentReminder3SmsThreshold",
  PAYMENT_REMINDER4_SMS_THRESHOLD = "paymentReminder4SmsThreshold",
  PAYMENT_REMINDER5_SMS_THRESHOLD = "paymentReminder5SmsThreshold"
}

export type MaintenanceBreak = {
  [EMaintenanceBreak.TYPE]?: EMaintenanceBreakType | "";
  [EMaintenanceBreak.STARTS_AT]: string;
  [EMaintenanceBreak.ENDS_AT]: string;
};

type SmsLanguage = {
  prefix: string;
  language: string;
};

export type OrderRule = {
  [ECLientPriority.ORDERS_THRESHOLD]: string;
  [ECLientPriority.PRIORITY]: string;
};

export type PeriodRule = {
  [ECLientPriority.STARTS_AT]: string;
  [ECLientPriority.ENDS_AT]: string;
  [ECLientPriority.PRIORITY]: string;
};

export type Prefix = {
  [EPrefixFields.PREFIX]: string;
  [EPrefixFields.CHANNEL]: "string";
};

export type TStoreParameters = {
  [EParameters.PURCHASE_CONFIRMATION_DOC]?: {
    [EParameterProperty.TYPE]: EPurchaseConfirmationDocType | "";
    [EParameterProperty.DOMAIN]: string;
  };
  [EParameters.DEFAULT_LANGUAGE]?: string;
  [EParameters.COUNTRY_CODE]?: string;
  [EParameters.DEFAULT_PREAUTH_AMOUNT]?: string;
  [EParameters.APP_PREAUTH_ENABLED]?: boolean; // false
  [EParameters.CURRENCY]?: string;
  [EParameters.ACTIVATION]?: {
    [EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS]?: boolean;
    [EParameterProperty.REQUIRE_FOR_NEW_CLIENTS]?: boolean;
  };
  [EParameters.SMS_LANGUAGES]?: SmsLanguage[];
  [EParameters.SMS]?: {
    [EParameterProperty.PREFIX_CONFIG]: {
      [EPrefixConfigFields.DEFAULT_CHANNEL]: string;
      [EPrefixConfigFields.PREFIXES]: Prefix[];
    };
    [EParameterPropertySms.WELCOME_SMS]: EMessage | "";
    [EParameterPropertySms.RECEIPT_SMS]: EMessage | "";
    [EParameterPropertySms.EXIT_SMS]: EMessage | "";
    [EParameterPropertySms.PAYMENT_FAILED_SMS]: EMessage | "";
    [EParameterPropertySms.PBL_REQUESTED_SMS]: EMessageNonDisable | "";
    [EParameterPropertySms.ACTIVATION_SMS]: EMessageNonDisable | "";
    [EParameterPropertySms.AUTO_RESCUE_INIT_SMS]: EMessage | "";
    [EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS]: EMessage | "";
    [EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS]: EMessage | "";
    [EParameterPropertySms.PAYMENT_REMINDER1_SMS]: EMessage | "";
    [EParameterPropertySms.PAYMENT_REMINDER2_SMS]: EMessage | "";
    [EParameterPropertySms.PAYMENT_REMINDER3_SMS]: EMessage | "";
    [EParameterPropertySms.PAYMENT_REMINDER4_SMS]: EMessage | "";
    [EParameterPropertySms.PAYMENT_REMINDER5_SMS]: EMessage | "";

    [EParameterPropertySmsThreshold.AUTO_RESCUE_INIT_SMS_THRESHOLD]: "";
    [EParameterPropertySmsThreshold.FIRST_RESCUE_ATTEMPT_SMS_THRESHOLD]: "";
    [EParameterPropertySmsThreshold.LAST_RESCUE_ATTEMPT_SMS_THRESHOLD]: "";
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER1_SMS_THRESHOLD]: "";
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER2_SMS_THRESHOLD]: "";
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER3_SMS_THRESHOLD]: "";
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER4_SMS_THRESHOLD]: "";
    [EParameterPropertySmsThreshold.PAYMENT_REMINDER5_SMS_THRESHOLD]: "";
  };
  [EParameters.PRODUCT_IMPORT]?: {
    [EParameterProperty.IMPORT_IMAGES_FROM_PIM]?: boolean;
    [EParameterProperty.AIFI_IMPORT_ENABLED]?: boolean;
  };
  [EParameters.CARD_ISSUER_ACTIONS]?: (
    | TCardIssuerActionBin
    | TCardIssuerActionScheme
  )[];
  [EParameters.CLIENT_PRIORITY]: {
    [ECLientPriority.ORDERS_RULES]: OrderRule[];
    [ECLientPriority.DAILY_PERIOD_RULES]: PeriodRule[];
    [ECLientPriority.PERIOD_RULES]: PeriodRule[];
    [ECLientPriority.VIP_RULE]: {
      [ECLientPriority.ENABLED]?: boolean;
    };
    [ECLientPriority.STORE_RULE]: {
      [ECLientPriority.PRIORITY]?: string;
    };
  };
};

export enum EStoreService {
  ERP = "erp",
  NANO_MONITOR = "nanoMonitor",
  SHORTENER = "shortener",
  SYNERISE = "synerise"
}

export enum EStoreServiceProperty {
  STORE_ID = "storeId",
  SERVICE_KEY = "serviceKey",
  IS_ENABLED = "isEnabled",
  VERIFICATION_APPROVER_EMAIL = "verificationApproverEmail",
  DOMAIN = "domain",
  SUPER_LOGIN_SERVICE_KEY = "superLoginServiceKey"
}

export type TStoreServices = {
  [EStoreService.ERP]?: {
    [EStoreServiceProperty.STORE_ID]?: string;
    [EStoreServiceProperty.SERVICE_KEY]?: string;
    [EStoreServiceProperty.IS_ENABLED]?: boolean;
  };
  [EStoreService.NANO_MONITOR]?: {
    [EStoreServiceProperty.VERIFICATION_APPROVER_EMAIL]?: string;
  };
  [EStoreService.SHORTENER]?: {
    [EStoreServiceProperty.DOMAIN]?: string;
    [EStoreServiceProperty.SERVICE_KEY]?: string;
  };
  [EStoreService.SYNERISE]?: {
    [EStoreServiceProperty.SERVICE_KEY]?: string;
    [EStoreServiceProperty.SUPER_LOGIN_SERVICE_KEY]?: string;
  };
};

export enum EComponent {
  AIFI = "aifi",
  ADYEN = "adyen",
  POS = "pos",
  INFOKIOSK = "infokiosk",
  POWER_BOUNCER = "powerBouncer"
}

export enum EComponentProperty {
  AIFI_ID = "aifiId",
  AIFI_ZONE_ID = "aifiZoneId",
  ENTRY_ID = "entryId",
  MONITORS = "monitors",
  STATE = "state",
  TERMINAL_ID = "terminalId",
  AVAILABILITY = "availability",
  PAPER_STATE = "paperState",
  SCOPE = "scope",
  HEARTBEAT_THRESHOLD = "heartbeatThreshold",
  TOKEN = "token",
  HELP_LINK = "helpLink",
  IO_CONTROLLER = "ioController",
  AGE_ESTIMATION = "ageEstimation",
  WORKER_ID = "workerId",
  POS_IP_ADDRESS = "posIpAddress",
  POS_PORT = "posPort",
  POS_USERNAME = "posUsername",
  POS_PASSWORD = "posPassword",
  OUTAGE_THRESHOLD = "outageThreshold",
  NO_OUTAGE_THRESHOLD = "noOutageThreshold",
  TRONITAG_ID = "tronitagId",
  FAKTUROWNIA_ID = "fakturowniaId",
  SERVICE_KEY = "serviceKey",
  TYPE = "type",
  POS_SERVICE = "posService",
  IPOS = "ipos",
  MIN_ADULT_AGE = "minAdultAge",
  QR_READER_AVAILABILITY = "qrReaderAvailability",
  STATUS = "status",
  MERCHANT_ACCOUNT = "merchantAccount",
  DEFAULT_LOCALE = "defaultLocale",
  MAINTENANCE_BREAKS = "maintenanceBreaks"
}

export enum EComponentType {
  POS_SERVICE = "POS_SERVICE",
  ERP = "ERP",
  IPOS = "IPOS"
}

export type TStoreComponents = {
  [EComponent.AIFI]: {
    [EComponentProperty.AIFI_ID]?: string;
    [EComponentProperty.ENTRY_ID]?: string;
    [EComponentProperty.MONITORS]?: {
      [EComponentProperty.STATE]?: TMonitor;
    };
  };
  [EComponent.ADYEN]: {
    [EComponentProperty.TERMINAL_ID]: string;
    [EComponentProperty.MONITORS]?: {
      [EComponentProperty.AVAILABILITY]?: TMonitor;
    };
  };
  [EComponent.POS]: {
    [EComponentProperty.SERVICE_KEY]?: string;
    [EComponentProperty.TYPE]?: EComponentType | "";
    // only for pos.type === "POS_SERVICE"
    [EComponentProperty.POS_SERVICE]?: {
      [EComponentProperty.WORKER_ID]: string;
      [EComponentProperty.POS_IP_ADDRESS]: string;
      [EComponentProperty.POS_PORT]?: string;
      [EComponentProperty.POS_USERNAME]: string;
      [EComponentProperty.POS_PASSWORD]: string;
      [EComponentProperty.OUTAGE_THRESHOLD]?: string;
      [EComponentProperty.NO_OUTAGE_THRESHOLD]?: string;
      [EComponentProperty.MAINTENANCE_BREAKS]?: MaintenanceBreak[];
    };
    [EComponentProperty.IPOS]?: {
      // only for pos.type === "IPOS"
      [EComponentProperty.TRONITAG_ID]: string;
      [EComponentProperty.FAKTUROWNIA_ID]: string;
    };
    [EComponentProperty.MONITORS]?: {
      // only for pos.type === "POS_SERVICE"
      [EComponentProperty.AVAILABILITY]?: TMonitor;
      [EComponentProperty.PAPER_STATE]?: TMonitor;
    };
  };
  [EComponent.INFOKIOSK]: {
    [EComponentProperty.SCOPE]: string;
    [EComponentProperty.SERVICE_KEY]?: string;
    [EComponentProperty.HEARTBEAT_THRESHOLD]?: string;
    [EComponentProperty.MONITORS]?: {
      [EComponentProperty.AVAILABILITY]?: TMonitor;
    };
  };
  [EComponent.POWER_BOUNCER]: {
    [EComponentProperty.TOKEN]?: string;
    [EComponentProperty.HELP_LINK]?: string;
    [EComponentProperty.HEARTBEAT_THRESHOLD]?: string;
    [EComponentProperty.AIFI_ZONE_ID]?: string;
    [EComponentProperty.IO_CONTROLLER]?: {
      [EComponentProperty.SERVICE_KEY]?: string;
    };
    [EComponentProperty.AGE_ESTIMATION]?: {
      [EComponentProperty.MIN_ADULT_AGE]?: string;
    };
    [EComponentProperty.MONITORS]?: {
      [EComponentProperty.AVAILABILITY]?: TMonitor;
      [EComponentProperty.QR_READER_AVAILABILITY]?: TMonitor;
    };
  };
};

export enum EProperty {
  OPENS_AT = "opensAt",
  CLOSES_AT = "closesAt",
  DAY = "day"
}
export type TDayOpeningHours = {
  [EProperty.OPENS_AT]: string; // format hh:mm
  [EProperty.CLOSES_AT]: string; // format hh:mm
};

export enum EDay {
  MON = "mon",
  TUE = "tue",
  WED = "wed",
  THU = "thu",
  FRI = "fri",
  SAT = "sat",
  SUN = "sun"
}

export enum ESchedule {
  BASE = "base",
  EXCEPTIONS = "exceptions",
  CLOSED_DAYS = "closedDays",
  ADJUSTED_DAYS = "adjustedDays",
  IS_ENABLED = "isEnabled"
}

export type TAdjustedDay = {
  [EProperty.DAY]: string; // DD/MM || DD/MM/YYYY
  [EProperty.OPENS_AT]: string; // hh:mm
  [EProperty.CLOSES_AT]: string; // hh:mm
};

export type TStoreBaseSchedule = {
  [EDay.MON]: TDayOpeningHours;
  [EDay.TUE]: TDayOpeningHours;
  [EDay.WED]: TDayOpeningHours;
  [EDay.THU]: TDayOpeningHours;
  [EDay.FRI]: TDayOpeningHours;
  [EDay.SAT]: TDayOpeningHours;
  [EDay.SUN]: TDayOpeningHours;
};

export type TStoreOpeningSchedule = {
  [ESchedule.BASE]?: TStoreBaseSchedule;
  [ESchedule.EXCEPTIONS]?: {
    [ESchedule.CLOSED_DAYS]?: string[];
    [ESchedule.ADJUSTED_DAYS]?: TAdjustedDay[];
  };
  [ESchedule.IS_ENABLED]?: boolean;
};

export enum EStore {
  STORE_ID = "storeId",
  CHAIN_ID = "chainId",
  CHAIN_NAME = "chainName",
  EXTERNAL_STORE_ID = "externalStoreId",
  NAME = "name",
  TYPE = "type",
  STATE = "state",
  ADDRESS = "address",
  MANAGER_EMAIL = "managerEmail",
  PARAMETERS = "parameters",
  SERVICES = "services",
  COMPONENTS = "components",
  OPENING_SCHEDULE = "openingSchedule",
  CREATED_AT = "createdAt"
}

export type TStore = {
  [EStore.STORE_ID]: string;
  [EStore.CHAIN_ID]: string;
  [EStore.EXTERNAL_STORE_ID]?: string;
  [EStore.NAME]: string;
  [EStore.TYPE]: EStoreType;
  [EStore.STATE]: EStoreState;
  [EStore.ADDRESS]: string;
  [EStore.MANAGER_EMAIL]: string;
  [EStore.PARAMETERS]: TStoreParameters;
  [EStore.SERVICES]: TStoreServices;
  [EStore.COMPONENTS]: TStoreComponents;
  [EStore.OPENING_SCHEDULE]: TStoreOpeningSchedule | null;
};

export enum StoreChainFields {
  CHAIN_ID = "chainId",
  NAME = "name",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  PARAMETERS = "parameters",
  SERVICES = "services",
  COMPONENTS = "components",
  OPENING_SCHEDULE = "openingSchedule"
}
