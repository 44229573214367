import { Box, Flex } from "@chakra-ui/react";
import { EStoreAvailability } from "api/common/storeState";
import { Formik, Form } from "formik";
import { Navigate, useParams } from "react-router-dom";

import { PathParameters, Routes } from "routes";
import { FailedFetchingAlert } from "components/alerts/Alerts";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import CustomBreadcrumbs from "components/customBreadcrumbs/CustomBreadcrumbs";
import { CustomSpinner } from "components/customSpinner/CustomSpinner";
import { HeaderWithOptions } from "components/headerWithOptions/HeaderWithOptions";
import { useRoles } from "hooks/roles/useRoles";
import { TStore } from "types/configFields";

import { useStoreContext } from "../../contexts/StoreContext";

import {
  CloseStoreModal,
  OpenStoreModal,
  StoreState,
  ValidateStoreConfigModal
} from "./components";
import SearchPanel from "./components/searchPanel/SearchPanel";
import { UnavailableStoreReportModal } from "./components/unavailableStoreReport/UnavailableStoreReportModal";
import Fields from "./Fields";
import { useStoreData } from "./hooks/useStoreData";
import { responseMapper } from "./mappers";

const CLOSED_REASONS = [
  EStoreAvailability.CLOSED_MAINTENANCE_COMPONENT,
  EStoreAvailability.CLOSED_MAINTENANCE_MANUALLY,
  EStoreAvailability.CLOSED_OTHER_REASON,
  EStoreAvailability.CLOSED_OUTSIDE_WORKING_HOURS
];

export function Store(): JSX.Element {
  const { canEditStores } = useRoles();
  const { [PathParameters.STORE_ID]: storeId } = useParams();
  const { storeChains } = useStoreContext();
  const {
    store,
    error,
    isLoading,
    posPaperState,
    storeState,
    refetchStoresState
  } = useStoreData(storeId);
  const currentChain =
    storeChains.find(chain => chain.chainId === store?.chainId) || null;
  // Temp unkown, types need refactor
  const mappedStore = responseMapper(store as unknown as TStore);

  const storeWithChainName = {
    ...mappedStore,
    chainName: currentChain?.name
  };

  if (!storeId) {
    return <Navigate to={Routes.STORE_CHAINS} />;
  }

  if (error) {
    return <FailedFetchingAlert message="Nieudane pobieranie danych sklepu" />;
  }

  if (!store || !currentChain) {
    return <CustomSpinner h="85vh" />;
  }

  const shouldShowOpenStoreButton = () => {
    if (
      storeState?.isClosedManually ||
      CLOSED_REASONS.includes(
        storeState?.storeAvailability as EStoreAvailability
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <CustomBreadcrumbs />
      <BorderedBox
        padding={{
          base: "1rem",
          lg: "3rem"
        }}
      >
        <HeaderWithOptions
          isEdit={canEditStores}
          title={`Sklep ${store?.name || storeId}`}
          additionalButtons={[
            ...[
              canEditStores && (
                <>
                  <UnavailableStoreReportModal
                    key="unavailableStoreReport"
                    isLoadingState={!!storeState?.isLoadingState}
                  />
                  <ValidateStoreConfigModal key="storeValidation" />
                </>
              )
            ],

            ...[
              canEditStores &&
                (shouldShowOpenStoreButton() ? (
                  <OpenStoreModal
                    key="openStoreModal"
                    onStateChange={refetchStoresState}
                    isLoadingState={!!storeState?.isLoadingState}
                  />
                ) : (
                  <CloseStoreModal
                    key="closeStoreModal"
                    onStateChange={refetchStoresState}
                    isLoadingState={!!storeState?.isLoadingState}
                  />
                ))
            ]
          ]}
        />
        <Flex>
          <StoreState posPaperState={posPaperState} storeState={storeState} />
        </Flex>
      </BorderedBox>
      <Box mt="1rem">
        <Flex
          maxHeight="74vh"
          overflow="auto"
          position="relative"
          id="store-wrapper"
        >
          <SearchPanel />
          {!!store && (
            <Box w="80%">
              <Formik initialValues={storeWithChainName} onSubmit={() => {}}>
                <Form>
                  <Fields isLoading={isLoading} isReadOnly />
                </Form>
              </Formik>
            </Box>
          )}
        </Flex>
      </Box>
    </>
  );
}
