import {
  StoreChainConfig,
  StoreChainConfigCreate
} from "api/common/storeChainConfig";

import { emptyBase } from "pages/store/consts";
import { getTypeFromDatetime } from "pages/store/mappers";
import {
  EDay,
  EProperty,
  ESchedule,
  EStore,
  TAdjustedDay,
  TStoreBaseSchedule,
  TStoreOpeningSchedule
} from "types/configFields";
import {
  dateFormatter,
  posServiceDatetimeFormatter,
  formatDateTimeToISOString,
  formatFromUTCTimeToLocal,
  timeFormatter
} from "utils/dateTime";

const openingScheduleMapper = (
  openingSchedule: TStoreOpeningSchedule | undefined | null,
  shouldFormatToLocal?: boolean
) => {
  if (!openingSchedule) {
    return null;
  }

  const { base, exceptions } = openingSchedule;

  let mappedBase: TStoreBaseSchedule | undefined;
  let mappedClosedDays: string[];
  let mappedAdjustedDays: TAdjustedDay[];

  const mergedWithEmptySchedule = Object.keys(emptyBase).reduce((acc, day) => {
    const dayKey = day as EDay;
    acc[dayKey] = {
      ...emptyBase[dayKey],
      ...(base ? base[dayKey] : {})
    };
    return acc;
  }, {} as TStoreBaseSchedule);

  if (!base) {
    mappedBase = undefined;
  } else {
    mappedBase = Object.fromEntries(
      Object.entries(mergedWithEmptySchedule).map(el => {
        const [dayName, value] = el;

        if (!value) {
          return [dayName, { opensAt: "", closesAt: "" }];
        }

        const formattedValue = Object.fromEntries(
          Object.entries(value).map(el => {
            const [intervalKey, time] = el;

            if (!time) {
              return [intervalKey, ""];
            }

            const formattedTime = shouldFormatToLocal
              ? formatFromUTCTimeToLocal(time)
              : timeFormatter(time, true);
            return [intervalKey, formattedTime];
          })
        );

        return [dayName, formattedValue];
      })
    ) as TStoreBaseSchedule;
  }

  if (exceptions && exceptions.closedDays) {
    mappedClosedDays = exceptions.closedDays.map(day => dateFormatter(day));
  } else {
    mappedClosedDays = [];
  }

  if (exceptions && exceptions.adjustedDays) {
    mappedAdjustedDays = exceptions.adjustedDays.map(day => {
      const {
        [EProperty.DAY]: dayName,
        [EProperty.OPENS_AT]: opensAt,
        [EProperty.CLOSES_AT]: closesAt
      } = day;

      return {
        [EProperty.DAY]: dateFormatter(dayName),
        [EProperty.OPENS_AT]: shouldFormatToLocal
          ? formatFromUTCTimeToLocal(opensAt)
          : timeFormatter(opensAt, true),
        [EProperty.CLOSES_AT]: shouldFormatToLocal
          ? formatFromUTCTimeToLocal(closesAt)
          : timeFormatter(closesAt, true)
      };
    });
  } else {
    mappedAdjustedDays = [];
  }

  return {
    [ESchedule.BASE]: mappedBase,
    [ESchedule.EXCEPTIONS]: {
      [ESchedule.CLOSED_DAYS]: mappedClosedDays,
      [ESchedule.ADJUSTED_DAYS]: mappedAdjustedDays
    },
    [ESchedule.IS_ENABLED]: !!openingSchedule.isEnabled
  };
};

export const payloadMapper = (payload: StoreChainConfigCreate) => {
  const { openingSchedule, components, ...restPayload } = payload;

  const mappedSchedule = openingScheduleMapper(openingSchedule);

  return {
    ...restPayload,
    components: {
      ...components,
      pos: {
        ...components?.pos,
        posService: {
          ...components.pos.posService,
          maintenanceBreaks:
            components?.pos?.posService?.maintenanceBreaks?.map(item => ({
              startsAt: posServiceDatetimeFormatter(item.startsAt, item.type),
              endsAt: posServiceDatetimeFormatter(item.endsAt, item.type)
            }))
        }
      }
    },
    [EStore.OPENING_SCHEDULE]: mappedSchedule
  };
};

export const responseMapper = (storeChain: StoreChainConfig | null) => {
  if (!storeChain) {
    return {};
  }

  const { openingSchedule, components, ...restResponse } = storeChain;
  const mappedOpeningSchedule = openingScheduleMapper(openingSchedule, true);

  return {
    ...restResponse,
    // TODO: Uncomment for SSD v.1.17
    // parameters: {
    //   ...parameters,
    //   sms: {
    //     ...parameters.sms,
    //     prefixConfig: replaceWithInitial(
    //       { defaultChannel: "" },
    //       parameters.sms?.prefixConfig
    //     )
    //   }
    // },
    components: {
      ...components,
      pos: {
        ...components?.pos,
        posService: {
          ...components.pos.posService,
          maintenanceBreaks:
            components?.pos?.posService?.maintenanceBreaks?.map(item => ({
              type: getTypeFromDatetime(item.startsAt),
              startsAt: formatDateTimeToISOString(item.startsAt),
              endsAt: formatDateTimeToISOString(item.endsAt)
            }))
        }
      }
    },
    openingSchedule: mappedOpeningSchedule
  };
};
